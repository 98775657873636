import React from "react"

import Banner from "../components/banner"
import Layout from "../components/layout"
import ScrollToTop from "../components/scrollToTop"
import SEO from "../components/seo"
import Tag from "../components/tag"

import ReconBanner from "../images/recon-banner.png"
import MyPic from "../images/mypic.jpg"
import Hey from "../images/hey.png"
import DesignSystem from "../images/design-system-w.png"
import Seamoss from "../images/seamoss.png"
import CoreplusCreditBanner from "../images/cp-credit-light.png"
import TafheemBanner from "../images/tafheem-banner.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Work" />
    <div className="container px-6 px-sm-2">
      <div className="d-flex align-items-center header-content">
        <div className="header-content-left w-md-100">
          <h1 className="header-h1">
            {/* <span role="img" aria-label="hello-emoji">
              👋
            </span>{" "} */}
            Hello, I'm <span className="bordered-font">Syed Hamza</span>
          </h1>
          <article className="header-p-wrapper">
            <p className="header-p">
              A <span className="highlight-text">Product UI/UX Designer</span>{" "}
              based in <span className="highlight-text">Pakistan</span> having
              5+ years of experience in delivering end to end UI/UX design for
              SAAS based products like{" "}
              <span className="highlight-text">Healthtech</span>,{" "}
              <span className="highlight-text">Fintech</span> etc.
            </p>
            <p className="header-p mt-2">
              {/* I'm passionate about creating and imporving user expeirences for
            software products through research based interaction and visual
            design. */}
              I'm passionate about building seamless product design experiences
              & processes through team collaboration (developers/Product
              Managers/Owners)
            </p>
            <p className="header-p mt-2">
              I've a growth mindset and insterested in working with mission
              driven comapnies.{" "}
            </p>
            <a
              href="mailto:smhamzame@gmail.com"
              target="_blank"
              className="btn-outline d-inline-flex mt-2"
              rel="noreferrer"
            >
              Get in touch
            </a>
          </article>
        </div>
        <div className="header-content-right ml-4 d-md-none">
          <img
            className="mypic m-0"
            width={400}
            src={MyPic}
            alt="CoreplusBanner"
          />
          <div>
            <img className="hey-emoji wave" width={100} src={Hey} alt="Hey" />
          </div>
        </div>
      </div>
      <div id="work" className="portfolio-content">
        <h2 className="header-h2">my recent work</h2>

        <div className="banner-container">
          <Banner
            img={DesignSystem}
            alt="coreplus-banner"
            color="lightgray"
            // route="/recon_design_system"
          >
            <h1>Design System for Coreplus V2 Webapp</h1>
            <p>A Healthtech CRM Dashboard</p>
            <div className="tags-container">
              <Tag name="Coming Soon" />
            </div>
          </Banner>
        </div>
        <div className="banner-container">
          <Banner
            bannerLeftClassName="p-0 py-sm-0"
            imgClassName="s-moss h-100 m-0"
            img={Seamoss}
            alt="seamoss"
            color="yellow"
            route="/seamoss_ecommerce"
          >
            <h1>SeaMoss Transformation</h1>
            <p>Redesining & Improving UX & CR</p>
            <div className="tags-container">
              <Tag name="UI/UX Design" />
              <Tag name="Style Guide" />
              <Tag name="UI Kit" />
              <Tag name="E-Commerce" />
            </div>
          </Banner>
        </div>
        <div className="banner-container">
          <Banner
            img={ReconBanner}
            alt="recon-banner"
            color="blue"
            route="/recon_design_system"
          >
            <h1>Recon Design System</h1>
            <p>A Fintech Analytical Dashboard</p>
            <div className="tags-container">
              <Tag name="Design System" />
              <Tag name="IA" />
              <Tag name="Style Guide" />
              <Tag name="Fintech" />
            </div>
          </Banner>
        </div>
        {/* <div className="banner-container mobile">
          <Banner
            img={TafheemBanner}
            alt="tafheem-banner"
            color="yellow"
            route="/tafheem_us_sunnah"
          >
            <h1>Tafheem Us Sunnah</h1>
            <p>Redesigning the Mobile App</p>
            <div className="tags-container">
              <Tag name="UI Kit" />
              <Tag name="Visual Design" />
              <Tag name="Mobile App" />
            </div>
          </Banner>
        </div> */}
      </div>
    </div>
    <ScrollToTop />
  </Layout>
)

export default IndexPage
